import server from '../request';

export default {
  getContactList (param, config) {
    param.orderByProperty = param.orderByProperty || 'lastUpdateTime';
    param.orderByType = param.orderByType || 'DESC';
    param.orderBy = [
      {
        orderByProperty: param.orderByProperty,
        orderByType: param.orderByType,
      },
    ];
    delete param.orderByType;
    delete param.orderByProperty;
    return server.post('/xiaozi-xmb/wireless/saas/contactPerson/page', param, config);
  },

  addContact (param) {
    return server.post('/xiaozi-xmb/wireless/saas/contactPerson/add', param);
  },

  xiaoziAddContact (param) {
    return server.post('/xiaozi-xmb/wireless/saas/customer/xzErp/customer/receiverGoodsAddress/add', param);
  },

  updateContact (param) {
    return server.post('/xiaozi-xmb/wireless/saas/contactPerson/update', param);
  },

  deleteContact (id) {
    let formData = new FormData();
    formData.append('id', id);
    return server.post('/xiaozi-xmb/wireless/saas/contactPerson/delete', formData,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },

  getDetail (id) {
    let formData = new FormData();
    formData.append('id', id);
    return server.post('/xiaozi-xmb/wireless/saas/contactPerson/detail', formData,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
    );
  },

  add ({applicantName, applicantPhoneNumber, applicantPosition, name, referrerId, referrerName, scale, trialProductId, trialProductName, type}) {
    return server.post('/xiaozi-saas/wireless/tenant/add', {applicantName, applicantPhoneNumber, applicantPosition, name, referrerId, referrerName, scale, trialProductId, trialProductName, type});
  },

  active ({id, trialPeriod}) {
    return server.post('/xiaozi-saas/wireless/tenant/active', {id, trialPeriod});
  },

  close ({id, closeReason}) {
    return server.post('/xiaozi-saas/wireless/tenant/close', {id, closeReason});
  },

  delete (id) {
    return server.post('/xiaozi-saas/wireless/tenant/delById', {id});
  },

  resetPwd ({id, newPwd, oldPwd }) {
    return server.post('xiaozi-saas/wireless/tenant/reset', {id, newPwd, oldPwd});
  },

  approve ({id, openType, adminAccount, adminPassword, trialPeriod, trialStatus, applicantName, applicantPhoneNumber, applicantPosition, name, referrerId, referrerName, scale, trialProductId, trialProductName, type}) {
    if (trialStatus === 'wait') {
      return server.post('/xiaozi-saas/wireless/tenant/trialPass', {id, openType, adminAccount, adminPassword, trialPeriod, applicantName, applicantPhoneNumber, applicantPosition, name, referrerId, referrerName, scale, trialProductId, trialProductName, type});
    } else {
      return server.post('/xiaozi-saas/wireless/tenant/activePass', {id, openType, adminAccount, adminPassword, trialPeriod});
    }
  },

  reject ({id, rejectReason, status}) {
    if (status === 'wait') {
      return server.post('/xiaozi-saas/wireless/tenant/trialReject', {id, rejectReason});
    } else {
      return server.post('/xiaozi-saas/wireless/tenant/activeReject', {id, rejectReason});
    }
  },
};

// 医院联系人状态更新
export const updateAccountStatus = (data) => server.post('/xiaozi-xmb/wireless/saas/contactPerson/updateStatus', data);
